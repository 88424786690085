import React, { useState, useEffect } from "react"
import axios from "axios"
import { toaster, Textarea, TextInputField, Button, Pane } from "evergreen-ui"
import { Link } from "gatsby"

export default () => {
  const [stuff, setStuff] = useState([])
  const [name, setName] = useState("")
  const [place, setPlace] = useState("")
  const [content, setContent] = useState("")

  useEffect(() => {
    axios
      .get("https://nurkowie-admin.c.krzysztofsikorski.pl/guides")
      .then(response => {
        setStuff(response.data)
      })
  }, [])

  const addOne = () => {
    axios
      .get(
        `https://nurkowie-admin.c.krzysztofsikorski.pl/guide?name=${name}&place=${place}&content=${content}`
      )
      .then(response => {
        toaster.success("Czekaj na moderację!")
        setName("")
        setPlace("")
        setContent("")
      })
  }
  console.log(stuff)

  return (
    <div
      style={{
        maxWidth: "640px",
        margin: "0 auto",
      }}
    >
      <Pane width="320px" marginX="auto" marginBottom="32px">
        <img
          src="/logo.png"
          style={{
            width: "100%",
          }}
        />
      </Pane>
      <Link to="/">
        <Pane textAlign="center">Do głównej</Pane>
      </Link>
      {stuff.map(s => (
        <div>
          <b>{s.name}</b> <i>({s.place})</i>
          <br />
          {s.content}
        </div>
      ))}
      <br />
      <TextInputField
        label="Imię"
        placeholder="Wpisz imię..."
        value={name}
        onChange={e => setName(e.target.value)}
      />
      <TextInputField
        label="Miejsce"
        placeholder="Wpisz miejsce..."
        value={place}
        onChange={e => setPlace(e.target.value)}
      />
      <Textarea
        placeholder="Komentarz..."
        value={content}
        onChange={e => setContent(e.target.value)}
      ></Textarea>
      <br />
      <br />
      <Button appearance="primary" onClick={addOne}>
        Wyślij
      </Button>
    </div>
  )
}
